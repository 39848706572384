/* eslint-disable */
import './cart-empty'
import './cart-o'
import './close'
import './credit-card-o'
import './credit-card'
import './deal-o'
import './document-o'
import './download-o'
import './download'
import './logo'
import './mp3'
import './pause-o'
import './paypal'
import './play-o'
import './search'
import './stems'
import './wav'
